import * as Icon from "react-bootstrap-icons";
import { FaUser, FaWallet, FaHistory, FaRegCreditCard, FaPlayCircle, FaShareAlt } from "react-icons/fa";
import { MdHelpCenter, MdStarRate, MdQuestionAnswer, MdKey } from "react-icons/md";
// import { MdQuestionAnswer } from "react-icons/md";
import { GrBarChart } from "react-icons/gr";
import { LuLogOut } from "react-icons/lu";
import { IoWarning } from "react-icons/io5";
import { GiLaurelsTrophy } from "react-icons/gi";
import { BiMoneyWithdraw } from "react-icons/bi";
import { RiBankCard2Line } from "react-icons/ri";
// import { MdQuestionAnswer } from "react-icons/md";
const user_id = localStorage.getItem("userid");
const handleShare = () => {
  if (navigator.share) {
    navigator.share({
      title: "Check out this app!",
      url: "https://dlmatka.com/API/public/api",
    })
      .then(() => console.log('Shared successfully'))
      .catch((error) => console.error('Error sharing:', error));
  } else {
    const unsupportedBrowserMessage = `
      Sharing is not supported in this browser.
      To share, please manually copy and paste the following link:
      https://dlmatka.com/API/public/api
    `;
    alert(unsupportedBrowserMessage);
  }
};

const Sidebardata = [
  {
    title: "My Profile",
    path: "/Profile",
    icon: <FaUser />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Change password",
    path: "Changepassword",
    icon: <MdKey />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },

  {
    title: "Logout",
    // path:"/Login",
    icon: <LuLogOut />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },


];
export default Sidebardata;