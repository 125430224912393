import React, { useState, useRef, useEffect } from "react";
// import { Button } from 'react-bootstrap';
import axios from "axios";
import { toast } from "react-toastify";
import loading from "../../assets/img/loading-gif.gif";
import filesearch from "../../assets/img/filesearch.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

export default function HarufbaseJodi() {
  const [areCombinationsGenerated, setAreCombinationsGenerated] =
    useState(false);

  const [loadingbutton, setLoadingbutton] = useState(false);
  const [anaderResult, setAnaderResult] = useState(new Set());
  const [baharResult, setBaharResult] = useState(new Set());
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [nums, setNums] = useState("");
  const [st, setSt] = useState([]);
  const [points, setPoints] = useState();
  const [totalPoints, setTotalPoints] = useState();
  const [MaxbetAmt, setMaxbet] = useState([]);
  const [setminibet, setMinibet] = useState([]);
  const [Maxpoints, setMaxpoints] = useState([]);
  // const url = new URL(window.location.href);
  // const gameid = url.searchParams.get('id');
  // const url1 = new URL(window.location.href);
  // const name = url1.searchParams.get('name');
  const isButtonLoading = useRef(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedResultType, setSelectedResultType] = useState("result");
  const [appmanagerdata, setAppmanager] = useState(null);
  const [minredeem, setMinredeem] = useState(null);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [totalPointsAnader, setTotalPointsAnader] = useState(0); // Separate state for Anader
  const [totalPointsBahar, setTotalPointsBahar] = useState(0); // Separate state for Bahar
  const [currentDate, setCurrentDate] = useState(getFormattedDate());

  function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }

  //   const numbers = [10, 20, 30, 40];
  // const st1 = new Set();

  // for (let i = 0; i < numbers.length; i++) {
  //   const tmp1 = numbers[i];
  //   console.warn(tmp1);
  //   st1.add(tmp1);
  // }

  // console.log(st1);
  // var s3 = '10';
  // var s4 = '20';
  //   for (let i = s3; i < s4.length; i++) {
  //     const tmp1 = s2[i];
  //     alert(tmp1)
  //     st.add(tmp1);
  //   }

  // const generateCombinations = () => {
  // if (!nums) {

  //   toast.error('Please enter number ',2000);
  //   return;
  // }
  // if (!points){

  //   toast.error('Please enter points ',2000);
  //   return;
  // }
  //   const numArray = Array.from(new Set(nums.split('').map(Number)));
  //   const n = numArray.length;
  //   let combinations = [];

  //   for (let i = 0; i < n; i++) {
  //     for (let j = 0; j < n; j++) {
  //       combinations.push([numArray[i], numArray[j]]);
  //     }
  //   }

  //   setCombinations(combinations);
  //   setAreCombinationsGenerated(true);
  // };
  // function handleChange(evt) {
  //   setNums(evt.target.value);
  // }

  const [s1, setS1] = useState("");
  // const [s2, setS2] = useState('');
  const [result, setResult] = useState(new Set());
  const [results, setResults] = useState(new Set());

  // console.warn(result);
  // useEffect(() => {
  //   setS2(s1);
  // }, [s1]);
  const handleCalculate = () => {
    if (!s1 || !points) {
      toast.error("Please enter valid values", 2000);
      return;
    }

    const anaderSet = new Set();
    const baharSet = new Set();

    for (let i = 0; i < 10; i++) {
      const tmp1 = i;
      anaderSet.add(tmp1 + s1);
      baharSet.add(s1 + tmp1);
    }

    if (selectedResultType === "result") {
      setAnaderResult(new Set([...anaderResult, ...anaderSet]));
    } else {
      setBaharResult(new Set([...baharResult, ...baharSet]));
    }

    setAreCombinationsGenerated(true);
  };

  const deleteCombination = (index) => {
    if (selectedResultType === "result") {
      const updatedCombinations = [...anaderResult];
      updatedCombinations.splice(index, 1);
      setAnaderResult(new Set(updatedCombinations));
    } else {
      const updatedCombinations = [...baharResult];
      updatedCombinations.splice(index, 1);
      setBaharResult(new Set(updatedCombinations));
    }
  };

  useEffect(() => {
    loaduser();
    app_manager();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const app_manager = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/app_setting.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    // formData.append('market_id', gameid);
    formData.append("dev_id", dev_id);
    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      var setpoints = response.data.points;
      setMaxpoints(setpoints);

      // console.warn(min_redeem)
      // setDeposit(mindeposit);
      const res = JSON.stringify(response.data.data);
      const objectRes = JSON.parse(res);
      setAppmanager(objectRes);
      var setmininumbet = objectRes.jodi_min;
      console.warn(setmininumbet);
      setMinibet(setmininumbet);
      var setmax_betnumbet = objectRes.jodi_max;
      console.warn(setmax_betnumbet);
      setMaxbet(setmax_betnumbet);
      // console.warn(setmax_betnumbet);
    });
  };
  const loaduser = async () => {
    // setLoading(true);
    const user_id = localStorage.getItem("userid");
    const gameid = localStorage.getItem("market_id");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    try {
      let url = `${process.env.REACT_APP_API_URL}/num_tbl.php`;
      // console.warn(url);
      const formData = new FormData();
      formData.append("app_id", process.env.REACT_APP_API_ID);
      formData.append("user_id", user_id);
      formData.append("market_id", gameid);
      formData.append("dev_id", dev_id);
      var config = {
        method: "POST",
        url: url,
        body: formData,
      };
      axios
        .post(url, formData, config)
        .then(function (response) {
          var setmininumbet = response.data.mini_bet;
          // var setmax_betnumbet = response.data.max_bet;
          var setpoints = response.data.points;
          setMinibet(setmininumbet);
          // setMaxbet(setmax_betnumbet)
          setMaxpoints(setpoints);
          console.warn(setpoints);
          const res = JSON.stringify(response.data);
          const objectRes = JSON.parse(res);
          setUsers(objectRes);
          console.warn(objectRes.points);
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      //   setLoading(false);
    }
  };

  const playgamecrossing = async () => {
    const gameid = localStorage.getItem("market_id");
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    if (parseInt(setminibet) > parseInt(points)) {
      toast.error(`Minimum Bet Placed ${appmanagerdata.jodi_min}`);
      return;
    }
    if (parseInt(MaxbetAmt) < parseInt(points)) {
      console.warn(MaxbetAmt);
      toast.error(`Maximum Bet Placed ${appmanagerdata.jodi_max}`);
      return;
    }
    if (users.points < combinationsCounts1) {
      toast.error(`You Dont Have Sufficient Balance`);
      return;
    }

    try {
      setLoadingbutton(true);

      const betList = (
        selectedResultType === "result"
          ? Array.from(anaderResult)
          : Array.from(baharResult)
      )
        .map((item, index) => {
          return {
            betkey: item,
            betamount: points,
            session_name: "open",
            bettype: "8",
          };
        })
        .filter((item) => item.betamount > 0);
      // setLoading2(true);
      // setLoading2(false);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/play_game.php`,
        {
          user_id: user_id,
          dev_id: dev_id,
          market_id: gameid,
          app_id: process.env.REACT_APP_API_ID,
          BetList: betList,
          dev_model: "web",
          market_type: "mainMarket",
          devName: "sumsang",
        }
      );

      setIsButtonVisible(true);
      if (response.data.success == 1) {
        // setShowSubmitButton(false);

        // console.warn(response.data.success)
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: response.data.message,
          icon: "success",
          timer: 2500,
        }).then((result) => {
          navigate("/");
        });
      } else {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: response.data.message,
          timer: 2000,
          icon: "error",
        });
        return;
      }
      // setCurrentDate(getFormattedDate());

      // return;
    } catch (error) {
      console.error("Game store:", error);
      toast.error(
        "An error occurred while Game store. Please try again later."
      );
    } finally {
      setLoadingbutton(false);
    }
  };

  const combinationsCount =
    selectedResultType === "result" ? anaderResult.size : baharResult.size;
  // const totalPoints = combinationsCount * points || 0;
  const combinationsCounts1 = combinationsCount * points || 0;

  return (
    <>
      <section id="crossing">
        {/* <div className="d-flex justify-content-between px-3">
          <div className="points">
            <h5>Points Remaining : {Math.max(0, users.points - combinationsCounts)}</h5>
          </div>
        </div> */}
        <div className="">
          <div className="inputbox mt-5">
            {/* <Form.Label>Choose Date</Form.Label> */}
            <input
              type="date"
              className="form-control icondate"
              value={currentDate}
              placeholder="Withdraw"
            />
          </div>

          <div className="inputbox mt-2">
            <ul className="Session_tabs bg-light d-flex rounded">
              <li className="d-flex align-items-center">
                <div className="tabs">
                  <input
                    type="radio"
                    value="result"
                    checked={selectedResultType === "result"}
                    onChange={() => setSelectedResultType("result")}
                  />
                  <label className="text-dark">Andar</label>
                </div>
              </li>
              <li className="d-flex align-items-center">
                <div className="tabs ">
                  <input
                    type="radio"
                    value="results"
                    checked={selectedResultType === "results"}
                    onChange={() => setSelectedResultType("results")}
                  />
                  <label className="text-dark">Bahar</label>
                </div>
              </li>
            </ul>
          </div>

          <div className="justify-conten-between formnumber">
            <div className="form-group d-flex align-items-center">
              <label className="text-white">First Jodi</label>
              <input
                type="text"
                inputMode="numeric"
                className="form-control"
                placeholder="1 to 9"
                value={s1}
                onPaste={(e) => {
                  e.preventDefault();
                  const pastedText = e.clipboardData
                    .getData("text/plain")
                    .replace(/[^0-9]/g, "")
                    .slice(0, 2);
                  document.execCommand("insertText", false, pastedText);
                }}
                onChange={(e) => {
                  const inputValue = e.target.value
                    .replace(/[^0-9]/g, "")
                    .slice(0, 2);
                  setS1(inputValue);
                }}
                required
              />
            </div>
          </div>
          <div className="formnumber">
            <div className="form-group d-flex align-items-center">
              <label className="text-white">Points</label>
              <input
                type="text"
                inputMode="numeric"
                className="form-control"
                placeholder="Enter Point Here"
                value={points}
                onPaste={(e) => {
                  e.preventDefault();
                  const pastedText = e.clipboardData
                    .getData("text/plain")
                    .replace(/[^0-9]/g, "")
                    .slice(0, 2);
                  document.execCommand("insertText", false, pastedText);
                }}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/[^0-9]/g, "");
                  setPoints(inputValue);
                }}
                required
              />
            </div>
          </div>

          <div className="form-btn">
            <button className="btn-add w-100" onClick={handleCalculate}>
              Add
            </button>
          </div>
        </div>

        <table className="table tablecrossing mb-2 mt-2">
          {selectedResultType === "result" && anaderResult.size > 0 ? (
            <thead>
              <tr>
                <th>Session</th>
                <th>Digit</th>
                <th>Points</th>
                <th>Action</th>
              </tr>
            </thead>
          ) : null}
          <tbody>
            {selectedResultType === "result" &&
              Array.from(anaderResult).map((item, index) => (
                <tr key={index}>
                  <td>Anader</td>
                  <td>{item}</td>
                  <td>{points}</td>
                  <td
                    className="text-danger"
                    onClick={() => deleteCombination(index)}
                  >
                    <i className="bi bi-trash3"></i>
                  </td>
                </tr>
              ))}
          </tbody>
          <tbody>
            {selectedResultType === "results" &&
              Array.from(baharResult).map((item, index) => (
                <tr key={index}>
                  <td>Bahar</td>
                  <td>{item}</td>
                  <td>{points}</td>
                  <td
                    className="text-danger"
                    onClick={() => deleteCombination(index)}
                  >
                    <i className="bi bi-trash3"></i>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="totalpoints d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <p className="text-black"> ₹</p>
            <p className="ms-1 text-black">{combinationsCounts1} /-</p>
          </div>

          {/* {showSubmitButton && <button  className='btn-add' onClick={playgamecrossing} disabled={!areCombinationsGenerated || isButtonDisabled}>
            <span >Submit</span>
          </button>} */}
          {users.remaining_time_in_seconds > 0 ? (
            <button
              className="btn-add"
              onClick={playgamecrossing}
              disabled={!areCombinationsGenerated || loadingbutton}
            >
              <span>Submit</span>
              {loadingbutton && <Spinner animation="border" />}
            </button>
          ) : (
            <button className="btn-add">
              <span>Bat Has Bin Colsed</span>
            </button>
          )}
        </div>
        <ToastContainer />
      </section>
      {loading2 && (
        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}
    </>
  );
}
