// import React, { useEffect } from "react";
// import { Col, Row, Form, Input } from "antd";
// import axios from "axios";
// import logo from "../../assets/img/logo.png";
// import { Card, Container } from "react-bootstrap";
// import { PhoneOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
// import * as Icon from 'react-bootstrap-icons';
// import { Link } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';
// import Cookies from "js-cookie";





// const ShopingLogout = () => {
//   const navigate = useNavigate()
//   useEffect(() => {
//     // const shopingLogin = localStorage.getItem('shopingLogin');
//     // if (shopingLogin === 'yes') {
//       Cookies.set("shopinglogin", 'no', { expires: 7 });
//       // localStorage.clear();
//       window.location.href = '/login';
//       alert('Are you sure Logout');
//       // navigate(0);
//     // }
//   }, []);

//   return (
//     <div className="margin-bottom-88 mb-0 Profile">

//     </div>
//   );
// };

// export default ShopingLogout;


// import React, { useEffect } from "react";
// import { useNavigate } from 'react-router-dom';
// import { Card } from "react-bootstrap";
// import { PhoneOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
// import Cookies from "js-cookie";
// import Swal from "sweetalert2";

// const ShopingLogout = () => {
//   const navigate = useNavigate();
//   let shopinglogin = Cookies.get("shopinglogin");



//   useEffect(() => {
//     let shopinglogin = Cookies.get("shopinglogin");
//     if (shopinglogin == 'no') {
//       Swal.fire({
//         title: 'Are You All Ready Logout!!!',
//         text: 'You will be log out!',
//         icon: 'warning',
//         // showCancelButton: true,
//         confirmButtonColor: '#3085d6',
//         // cancelButtonColor: '#d33',
//         confirmButtonText: 'Ok !'
//       }).then((result) => {
//         if (result.isConfirmed) {
//           navigate('/Login');
//         }
//       });
//     }

//   }, [navigate]);

//   useEffect(() => {
    
//     const handleLogout = () => {
//       // Set a cookie to indicate logout
//       // Cookies.set("shopinglogin", 'no', { expires: 7 });

//       // Display confirmation dialog
//       Swal.fire({
//         title: 'Are you sure?',
//         text: 'You will be logged out!',
//         icon: 'warning',
//         showCancelButton: true,
//         confirmButtonColor: '#3085d6',
//         cancelButtonColor: '#d33',
//         confirmButtonText: 'Log Out !'
//       }).then((result) => {
//         if (result.isConfirmed) {
//           // Clear local storage
//           localStorage.clear();
//           // Redirect to login page
//           // navigate('/login');
//           Cookies.set("shopinglogin", 'no', { expires: 20 });
//           window.location.href = '/login';
//         } else {
//           window.location.href = 'https://timebazarofficial.in/Shoping/';
//         }
//       });
//     };

//     handleLogout();
//   }, [navigate]);

//   return (
//     <div className="margin-bottom-88 mb-0 Profile">
//       {/* Content for your logout component */}
//     </div>
//   );
// };

// export default ShopingLogout;
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import Swal from "sweetalert2";

const ShopingLogout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = () => {
      let shopinglogin = Cookies.get("shopinglogin");
      if (shopinglogin === 'yes') {
        Swal.fire({
          title: 'Are You Sure You Want to Logout?',
          text: 'You will be logged out!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Log Out'
        }).then((result) => {
          if (result.isConfirmed) {
            // Clear local storage
            localStorage.clear();
            Cookies.set("shopinglogin", 'no', { expires: 20 });
            // Cookies.clear();
            // Set the shopinglogin cookie value to 'no' and expire after 20 days
            // Redirect to login page
            // window.location.href = '/login';
            navigate('/Login');
          } else {
            // Redirect to the shopping page
            window.location.href = 'https://timebazarofficial.in/Shoping/';
          }
        });
      } else {
        // Redirect to the shopping page if not logged out
        Swal.fire({
          title: 'Are You All Ready Logout!!!',
          text: 'You will be logged out!',
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: '#3085d6',
          // cancelButtonColor: '#d33',
          confirmButtonText: 'OK !'
        }).then((result) => {
          if (result.isConfirmed) {
            
            // window.location.href = '/login';
            navigate('/Login');
            Cookies.clear();
          } 
          else {
            // Redirect to the shopping page
            window.location.href = 'https://timebazarofficial.in/Login';
          }
        });
      }
    };

    handleLogout();
  }, [navigate]);

  return (
    <div className="margin-bottom-88 mb-0 Profile">
      {/* Content for your logout component */}
    </div>
  );
};

export default ShopingLogout;
