import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { PlusOutlined } from "@ant-design/icons";
import withdrawal from "../../assets/img/withdrawal.png";
import withdrawal_history from "../../assets/img/withdrawal_history.png";
import add from "../../assets/img/add.png";
import add_fund from "../../assets/img/add_fund.png";
import wallet from "../../assets/img/wallet.png";
import { GiReceiveMoney } from "react-icons/gi";
import axios from "axios";
import * as Icon from 'react-bootstrap-icons';

function Wallet() {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    loaduser();
  }, []); // Empty dependency array for initial component mount

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    try {
      const response = await axios.post(url, formData);
      console.log("API Response:", response);
      const res = response.data;
      if (res !== undefined) {
        setProfiles(res);
        console.warn(res);
      }
    } catch (error) {
      console.error("Error fetching profile data: ", error);
    }
  };

  
  
  return (
    <div className="wallet_bank">
      <Container>
        

        <Row className="d-flex mt-2 flex-wrap" justify={"start"}>
          <Col span={12}>
          <Link to="/AddPayment">
          <div className="add_bank">
         <div className="image_wallet">
         <img src={add}/>
         </div>
              <p  className="text-dark">Add Fund</p>
              {/* <span>{profiles.credit}</span> */}

            </div>
          </Link>
          </Col>
          <Col span={12}>
          <Link to="/WithdrawAmount">
            
            <div className="add_bank">
            <div className="image_wallet">
              <img src={withdrawal}/>
              </div>
              <p  className="text-dark">Withdrawal</p>
            </div>
          </Link>
          </Col>
          <Col span={12}>
          <Link to="/Add-fund-history">
          <div className="add_bank">
          <div className="image_wallet">
              <img src={add_fund}/>
          </div>
              <p className="text-dark">Add Fund History</p>

            </div>
          </Link>
          
          </Col>
          <Col span={12}>
          <Link to="/Withdrawhistory">
            <div className="add_bank">
            <div className="image_wallet">
            <img src={withdrawal}/>
            </div>
              <p  className="text-dark">Withdrawal History</p>
            </div>
          </Link>
          </Col>
    <Col span={24} className="d-flex justify-content-center"> 
    <Link to="/Wallet-history">
            <div className="add_bank">
            <div className="image_wallet">
              <img
                src={wallet}
                alt="withdrawal"
                className="img-fluid withdrawal PayIcon"
              />
              </div>
              <p  className="text-dark">Wallet History</p>
            </div>
          </Link>
          </Col>
        </Row>

        
      </Container>
    </div>
  );
}

export default Wallet;
