import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import loading1 from '../../assets/img/loading-gif.gif';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import { UserOutlined } from '@ant-design/icons';
// import { PhoneOutlined } from '@ant-design/icons';
import Tab from 'react-bootstrap/Tab';
import Logo from '../../assets/img/logo.png';
import { Spinner } from "react-bootstrap";

import { Container } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons'
import { Button, Form, Input, message, Space, Modal } from 'antd';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
export default function AddPayment() {

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const [selectedOption, setSelectedOption] = useState(true);
  const [amountvalue, setvalue] = useState("");
  const [users, setUsers] = useState([]);
  const [user, setUsers1] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [userData, setUserData] = useState(null);
  const [appmanager, setAppmanager] = useState(null);
  const [minredeem, setMinredeem] = useState(null);
  const [Deposit, setDeposit] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingbutton, setLoading] = useState(false);

  const changevalue = (e) => {
    setvalue(e.target.value)
  }
  const handleButtonClickdeposit = (amount) => {
    // setSelectedAmount(amount);
    setInputValuedeposit(amount);
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const [form] = Form.useForm();

  const onFinish = () => {
    // message.success('Submit success!');
  };

  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  useEffect(() => {

    getuser();
    app_manager();
  }, [])


  // const user_id = localStorage.getItem("userid");
  // const dev_id = localStorage.getItem("dev_id");

  const getuser = async () => {


    const userId = localStorage.getItem('userid');
    const devId = localStorage.getItem('dev_id');

    const apiUrl = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);

    formData.append('user_id', userId);
    formData.append('dev_id', devId);

    // newFormData.append("userId", user_id);
    // newFormData.append("device_id", dev_id);

    fetch(apiUrl, {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === "1") {
          setUserData(data);
        } else {
          console.error("API Error:", data.message);
        }
      })

      .catch(error => {
        console.error("Error:", error);
      });
    if (!userId) {
      window.location.href = '/';
      // exist();
    }
  };
  const app_manager = async () => {

    const userId = localStorage.getItem('userid');
    const devId = localStorage.getItem('dev_id');


    let url = (`${process.env.REACT_APP_API_URL}/app_setting.php`);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);



    formData.append('user_id', userId);
    formData.append('dev_id', devId);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        var min_redeem = response.data.data.min_redeem;
        var mindeposit = response.data.data.minimum_deposit;
        // console.warn(min_redeem)
        setMinredeem(min_redeem);
        setDeposit(mindeposit);
        console.warn(mindeposit);
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        setAppmanager(objectRes);
        setIsLoading(false);

      })

  }



  const userId = localStorage.getItem('userid');
  const devId = localStorage.getItem('dev_id');




  const [inputValuedeposit, setInputValuedeposit] = useState('');



  // const handleDeposit = () => {
  //   if (inputValuedeposit) {
  //     const apiUrl = `https://luckymatka.a2logicgroup.com/Admin/public/api/deposit_auto.php?name=${userData.name}&user_id=${userId}&amount=${inputValuedeposit}&contact=${userData.mob}&getaway=razorpay`;
  //     window.location.href = apiUrl;
  //   } else {
  //   }
  // };


  const handleDeposit = () => {

    if (!inputValuedeposit) {

      toast.error('Please enter a valid Value !');
      return;
    }
    if (parseInt(Deposit) > parseInt(inputValuedeposit)) {
      // alert('5')
      // console.warn(setminibet);
      toast.error(`Minimum Deposit Amount ${appmanager.minimum_deposit
        }`);
      return;
    }
    try {
      if (inputValuedeposit) {
        setLoading(true);
        const apiUrl = `https://timebazarofficial.in/Admin/public/do-payment?userid=${userId}&amount=${inputValuedeposit}`;
        window.location.href = apiUrl;
      } else {
        // Display an error message or handle appropriately
        // toast.error(`Minimum deposit amount is ₹500`);
      }

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);

    }
    finally {
      setLoading(false);
    }
  };








  return (
    <>



      <section id="wallet" className="margin-bottom-88 mt-5">
        <Container fluid className="p-0 margin-bottom-70">
          <div className="homecontainer">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="w-100 mt-5">

              <Nav.Item>
                <Nav.Link eventKey="first" className='text-center AddPoint mt-3'>Add Point</Nav.Link>
              </Nav.Item>


              <div >
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className='tab_content_one position-relative'>
                      <div className='addfundwallet'>
                        <div className="bankicon">
                          <Icon.Bank />
                        </div>
                        <input type="Number" value={inputValuedeposit} onChange={(e) => setInputValuedeposit(e.target.value)} required className='form-control addamountinput' placeholder='Add Amount' />
                      </div>
                      <div className='button-amount d-flex flex-wrap'>
                        <button className='btn-amount' onClick={() => handleButtonClickdeposit("500")}>₹ 500</button>
                        <button className='btn-amount' onClick={() => handleButtonClickdeposit("1000")}>₹ 1000</button>
                        <button className='btn-amount' onClick={() => handleButtonClickdeposit("1500")}>₹ 1500</button>
                        <button className='btn-amount' onClick={() => handleButtonClickdeposit("2000")}>₹ 2000</button>
                        <button className='btn-amount' onClick={() => handleButtonClickdeposit("2500")}>₹ 2500</button>
                        <button className='btn-amount' onClick={() => handleButtonClickdeposit("3000")}>₹ 3000</button>
                      </div>
                      <p className="description mt-2 text-danger">{appmanager && appmanager.dep_message}</p>
                      <center>


                        {/* <h5>Win Amount :<strong>0</strong></h5> */}
                        <div className="d-flex justify-content-between mt-2 mb-1">
                          <button disabled={loadingbutton} className=' btnaddpoints mb-4' onClick={handleDeposit}>
                            Add Points
                            {loadingbutton && <Spinner animation="border" />}
                          </button>

                        </div>
                        <div className='d-flex justify-content-center radiobtn'>





                        </div>

                      </center>
                    </div>
                    {/* <div className='table-responsive'>
                      <p className='text-center mt-2 requesthistory'>Request History</p>
                    </div> */}

                  </Tab.Pane>


                </Tab.Content>
              </div>

            </Tab.Container>
          </div>
        </Container>
        <ToastContainer />

      </section>
    </>
  )
}
