import React, { useState, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from "axios";
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";

function AddBank() {
  const [selectedPayment, setSelectedPayment] = useState("");
  const [formData, setFormData] = useState({
    Gpay: "",
    Paytm: "",
    phonePe: "",
    acHolderName: "",
    accountNo: "",
    bankName: "",
    ifscCode: "",
  });



  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const [loadingbutton, setLoadingbutton] = useState(false);
  const handlePaymentChange = (e) => {
    setSelectedPayment(e.target.value);
  };

  const handleInputChange = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
  };

  const validateForm = () => {
    const errors = {};

    if (selectedPayment === "phonePay" && !formData.phonePe) {
      errors.phonePe = "PhonePe number is required";
    }

    if (selectedPayment === "paytm" && !formData.Paytm) {
      errors.Paytm = "Paytm number is required";
    }

    if (selectedPayment === "gpay" && !formData.Gpay) {
      errors.Gpay = "GPay number is required";
    }

    if (selectedPayment === "bankAccount") {
      if (!formData.acHolderName) {
        errors.acHolderName = "A/C Holder Name is required";
      }

      if (!formData.accountNo) {
        errors.accountNo = "Account Number is required";
      }

      if (!formData.bankName) {
        errors.bankName = "Bank Name is required";
      }

      if (!formData.ifscCode) {
        errors.ifscCode = "IFSC Code is required";
      }
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const loadUser = async () => {
    const isValid = validateForm();

    if (!isValid) {
      return;
    }
    try {
      setLoadingbutton(true);

    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    const url = `${process.env.REACT_APP_API_URL}/Add_bank_detail.php`;

    const newFormData = new FormData();
    newFormData.append("app_id", process.env.REACT_APP_API_ID);
    newFormData.append("userId", user_id);
    newFormData.append("device_id", dev_id);
    newFormData.append("phone_pay", formData.phonePe);
    newFormData.append("paytm_number", formData.Paytm);
    newFormData.append("google_pay", formData.Gpay);
    newFormData.append("account_number", formData.accountNo);
    newFormData.append("account_holder_name", formData.acHolderName);
    newFormData.append("ifsc_code", formData.ifscCode);
    newFormData.append("bank_name", formData.bankName);


      const response = await axios.post(url, newFormData);
      const responseData = response.data;

      const MySwal = withReactContent(Swal);
      if (responseData.success === "1") {
        MySwal.fire({
          title: responseData.message,
          icon: 'success',
          timer: 2500
        }).then((result) => {
          navigate('/');
        });
      } else {
        MySwal.fire({
          title: responseData.message,
          timer: 2000,
          icon: 'error',
        });
      }
      // if (data.dark === "1") {
      //   MySwal.fire({
      //     title: data.message,
      //     icon: 'dark',
      //     timer: 2500
      //   });
      // } else {
      //   MySwal.fire({
      //     title: "Wrong",
      //     timer: 2000,
      //     icon: 'error',
      //   });
      // }
    } catch (error) {
      console.error("Error submitting payment details:", error);
    }
    finally {
      setLoadingbutton(false);
    }
    
  };


  const [userss, setUserss] = useState("")


  const loaduserr = async () => {
    const user_id = localStorage.getItem('userid');
    const dev_id = localStorage.getItem('dev_id');
    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      const res = JSON.stringify(response.data);
      const objectRes = JSON.parse(res);
      setUserss(objectRes);
    });
  };



  useEffect(() => {
    loaduserr();
  }, [])

  return (
    <div className="Add_bank-detail">


      {/* <div className="d-flex justify-content-between align-items-center">
        <div className="headericonarrow">
          <Link className="arrowlink" to="/">
            <i class="bi bi-arrow-left-short text-dark"></i>
          </Link>
        </div>
        <div className="chatname">
          <h2 className="text-dark text-center"> <span></span>Add Bank Detais</h2>
        </div>

      </div> */}

      <Container className="">
        <div className="margin-top-bottom">
          <div className="d-flex align-items-center">
            <div className="imgageuser">
              <img src="https://img.freepik.com/premium-psd/3d-cartoon-man-smiling-portrait-isolated-transparent-background-png-psd_888962-1570.jpg" alt="" />
            </div>
            <div>
              <h6 className="text-light">UserName : <span className="fw-bold">{userss.name}</span></h6>
              <p className="mb-0 text-light"><span>Wallet:</span> {userss.credit}</p>
            </div>
          </div>
          <div className="inputbox">
            <div className="input-group">
              <input
                type="number"
                className="inputbox form-control"
                placeholder="PhonePe No :-"
                value={formData.phonePe}
                onChange={(e) => handleInputChange(e, "phonePe")}
              />
              <div className=" border-secondary rounded input-group-append positionedit">
                <span className="">
                  <i className="bi bi-pen-fill text-dark "></i>
                </span>
              </div>
            </div>
            {validationErrors.phonePe && (
              <div className="text-danger">{validationErrors.phonePe}</div>
            )}
          </div>
          <div className="inputbox">
            <div className="input-group">
              <input
                type="number"
                className="inputbox form-control"
                placeholder="Paytm No :-"
                value={formData.Paytm}
                onChange={(e) => handleInputChange(e, "Paytm")}
              />
              <div className=" border-secondary rounded input-group-append positionedit">
                <span className="">
                  <i className="bi bi-pen-fill text-dark "></i>
                </span>
              </div>
            </div>
            {validationErrors.Paytm && (
              <div className="text-danger">{validationErrors.Paytm}</div>
            )}
          </div>
          <div className="inputbox">
            <div className="input-group">
              <input
                type="number"
                className="inputbox form-control"
                placeholder="GPay No :-"
                value={formData.Gpay}
                onChange={(e) => handleInputChange(e, "Gpay")}
              />
              <div className=" border-secondary rounded input-group-append positionedit">
                <span className="">
                  <i className="bi bi-pen-fill text-dark "></i>
                </span>
              </div>
            </div>
            {validationErrors.Gpay && (
              <div className="text-danger">{validationErrors.Gpay}</div>
            )}
          </div>








        </div>
        <div className="inputbox">

          <div className="bankdetials">
            <div className="d-flex justify-content-between ">
              <label className="text-light" >Bank Detail</label>
              <i className="bi bi-pen-fill text-light  " style={{ marginLeft: '23px' }}></i>
            </div>
            <div className="inputbox px-0">
              <input
                type="text"
                className="border border-secondary  form-control"
                placeholder="A/C Holder Name :-"
                value={formData.acHolderName}
                onChange={(e) => handleInputChange(e, "acHolderName")}
              />
              {validationErrors.acHolderName && (
                <div className="text-danger">{validationErrors.acHolderName}</div>
              )}
            </div>

            <div className="inputbox px-0">
              {/* <label className="text-dark">Account No</label> */}
              <input
                type="Number"
                className="border border-secondary  form-control"
                placeholder="Account No :-"
                value={formData.accountNo}
                onChange={(e) => handleInputChange(e, "accountNo")}
              />
              {validationErrors.accountNo && (
                <div className="text-danger">{validationErrors.accountNo}</div>
              )}
            </div>

            <div className="inputbox px-0">
              {/* <label className="text-dark">Bank Name</label> */}
              <input
                type="text"
                className="border border-secondary  form-control"
                placeholder="Bank Name :-"
                value={formData.bankName}
                onChange={(e) => handleInputChange(e, "bankName")}
              />
              {validationErrors.bankName && (
                <div className="text-danger">{validationErrors.bankName}</div>
              )}
            </div>

            <div className="inputbox px-0">
              {/* <label className="text-dark">IFSC Code</label> */}
              <input
                type="text"
                className="border border-secondary form-control"
                placeholder="  IFSC Code :-"
                value={formData.ifscCode}
                onChange={(e) => handleInputChange(e, "ifscCode")}
              />
              {validationErrors.ifscCode && (
                <div className="text-danger">{validationErrors.ifscCode}</div>
              )}
            </div>


          </div>
            <div className="inputbox px-0">
              <Button type="submit" className="w-100 Submit_btn" disdisabled={loadingbutton} onClick={loadUser}>
                Submit
                {loadingbutton && <Spinner animation="border" />}
              </Button>
            </div>

        </div>
      </Container>
    </div>
  );
}

export default AddBank;
