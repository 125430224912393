import React, { useState, useEffect } from 'react';
import axios from "axios";

export default function Resulthistory() {
  const [profiles, setProfiles] = useState({});
  
  useEffect(() => {
    getprofile();
  }, []);

  useEffect(() => {
    // Check if profiles exist and is_playstore is not 1
    if (profiles && profiles.is_playstore !== 1) {
      // Auto-click the link after a delay of 1 second
      setTimeout(() => {
        document.getElementById('shopNowLink').click();
      }, 1000);
    }
  }, [profiles]);

  const getprofile = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    try {
      const response = await axios.post(url, formData);
      console.log("API Response:", response);
      const res = response.data;
      if (res !== undefined) {
        setProfiles(res);
        console.log(res);
      }
    } catch (error) {
      console.error("Error fetching profile data: ", error);
    }
  };

  return (
    <>
      <iframe title="shopNowFrame" style={{ display: 'none' }} src="https://timebazarofficial.in/Shoping/" id="shopNowLink" />
    </>
  )
}
