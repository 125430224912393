import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './Style.scss';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Login from './Component/Login/Login';
import Home from './Component/Home';
import Wallet from './Component/Page/Wallet';
import Play from './Component/Page/Play';
import Shoping from './Component/Page/Shoping';
import Jodi from './Component/Jodi_game/Jodi';
import Register from './Component/Login/Register';
import Changepassword from './Component/Page/Changepassword';
import ForgotPassword from './Component/Page/ForgotPassword';
import AddBank from './Component/Page/AddBank';
import WithdrawAmount from './Component/Page/WithdrawAmount';
import AddPayment from './Component/Page/AddPayment';
import MarketInner from './Component/Page/MarketInner';
import FAQ from './Component/Page/FAQ';
import Marcetret from './Component/Page/Marcetret';
import Playgame from './Component/Page/Playgame';
import Harruf from './Component/Jodi_game/Harruf';
import Profile from './Component/Page/Profile';
import Share from './Component/Page/Share';
import ShopingLogout from './Component/Page/ShopingLogout';
import Loader from './Component/Page/Loader';
import Resulthistory from './Component/Page/Resulthistory';
import Notification from './Component/Page/Notification';
import Instruction from './Component/Page/Instruction';
import Howtouse from './Component/Page/Howtouse';
import '.././src/assets/fonts/font1/stylesheet.css';
import '.././src/assets/fonts/font-2/stylesheet.css';
import Header from ".././src/Component/Header/Header";
import Headercommon from ".././src/Component/Header/Headercommon";
import Footer from ".././src/Component/Footer";
import Jodi_Pana1 from './Component/Jodi_game/Jodi_Pana';
import Playgamedelhi from './Component/Jodi_game/Playgamedelhi';
import Crossing from './Component/Jodi_game/Crossing';
import Numbertonumber from './Component/Jodi_game/Numbertonumber';
import HarufbaseJodi from './Component/Jodi_game/HarufbaseJodi';
import PlayJantrl from './Component/Jodi_game/PlayJantrl';
import Play_Rashi_Jodi from './Component/Jodi_game/Play_Rashi_Jodi';


//------History page--------//

import Withdrawhistory from './Component/Historypage/Withdrawhistory';
import Wallethistory from './Component/Historypage/Wallethistory';
import Winighistory from './Component/Historypage/Winighistory';
import Addfoundhistory from './Component/Historypage/Addfoundhistory';
import Bithistory from './Component/Historypage/Bithistory';

//--------Kalyan Market-----------//

import Pana from './Component/Kalyanmarket/Pana';
import Gali from ".././src/Component/Kalyanmarket/Gali";
import Jodidigit from './Component/Kalyanmarket/Jodidigit';
import Singlapana from './Component/Kalyanmarket/Singlapana';
import Doublepana from './Component/Kalyanmarket/Doublepana';
import Troplepana from './Component/Kalyanmarket/Troplepana';
import Fullsangam from './Component/Kalyanmarket/Fullsangam';
import Halfsangam from './Component/Kalyanmarket/Halfsangam';
import { useNavigate } from 'react-router-dom';
// import { useBackListener } from '../path/to/useBackListener';


function App() {
  const [refreshing, setRefreshing] = useState(false);
  const [refreshText, setRefreshText] = useState();
  const [startY, setStartY] = useState(0);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    if (scrollTop === 0 && !refreshing) {
      setRefreshText('Release to refresh');
    } else {
      setRefreshText();
    }
  };

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchEnd = (e) => {
    if (refreshing) return;

    const endY = e.changedTouches[0].clientY;
    if (endY - startY > 50) {
      setRefreshing(true);
      setRefreshText(
        <div className="refesh_btn_bg">
          <div id="loading-bar-spinner" class="spinner">
            <div class="spinner-icon"></div>
          </div>
        </div>
      );
      // Simulate data fetching delay
      setTimeout(() => {
        setRefreshing(false);
        setRefreshText();
        window.location.reload(); // Reload the page
      }, 1000); // Change the time as per your requirement
    }
  };

  return (
    <>
      <div
        className="refresh_pull"
        onScroll={handleScroll}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <div className="resfreshcontent">{refreshText}</div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<><Play /> <Header /></>} />
            <Route path="/ShopingLogout" element={<><ShopingLogout /></>} />
            <Route
              path="/*"
              element={
                <div>
                  <Header />
                  <Routes>

                    <Route path="/Share" element={<Share />} />
                    <Route path="/Howtouse" element={<Howtouse />} />
                    <Route path="/FAQ" element={<FAQ />} />
                    <Route path="/Changepassword" element={<Changepassword />} />
                    <Route path="/Notification" element={<Notification />} />
                    <Route path="/Shoping" element={<><Shoping /></>} />
                   
                  </Routes>
                </div>
              }
            />
            <Route path="/Login" element={<Login />} />
            <Route path="/Pana" element={<><Pana /></>} />


            <Route path="/Playgame-jodi" element={<Jodi_Pana1 />} />
            <Route path="/Jodi-Game" element={<Playgamedelhi />} />
            <Route path="/Jodi" element={<><Jodi /></>} />
            <Route path="/Crossing" element={<><Crossing /><Headercommon /></>} />
            <Route path="/Number-Number" element={<><Numbertonumber /><Headercommon /></>} />
            <Route path="/Haruf-Base-Jodi" element={<><HarufbaseJodi /><Headercommon /></>} />
            <Route path="/Play-Jantri" element={<><PlayJantrl /><Headercommon /></>} />
            <Route path="/Play-Rashi-Jodi" element={<><Play_Rashi_Jodi /><Headercommon /></>} />
            <Route path="/Harruf" element={<><Harruf /></>} />
            <Route path="/AddPayment" element={<><AddPayment /><Headercommon /></>} />
            <Route path="/Playgame" element={<><Playgame /><Headercommon /></>} />
            <Route path="/Result-history" element={<><Headercommon /><Resulthistory /></>} />
            <Route path="/Register" element={<Register />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/Singlapana" element={<><Singlapana /></>} />
            <Route path="/Gali" element={<><Gali /></>} />
            <Route path="/Jodidigit" element={<><Jodidigit /></>} />
            <Route path="/Doublepana" element={<><Doublepana /></>} />
            <Route path="/Troplepana" element={<><Troplepana /></>} />
            <Route path="/Halfsangam" element={<><Halfsangam /></>} />
            <Route path="/Fullsangam" element={<><Fullsangam /></>} />
            <Route path="/Win-History" element={<><Winighistory /><Headercommon /></>} />
            <Route path="/Profile" element={<><Profile /><Headercommon /></>} />
            <Route path="/Bid-history" element={<><Bithistory /><Headercommon /></>} />
            <Route path="/Wallet" element={<><Wallet /><Headercommon /></>} />
            <Route path="/Instruction" element={<><Instruction /><Headercommon /></>} />
            <Route path="/Delhi-Market" element={<><MarketInner /><Headercommon /></>} />
            <Route path="/Market-ret" element={<><Marcetret /><Headercommon /></>} />
            <Route path="/Add-Bank-Detais" element={<><AddBank /><Headercommon /></>} />
            <Route path="/WithdrawAmount" element={<><WithdrawAmount /><Headercommon /></>} />
            <Route path="/Withdrawhistory" element={<><Withdrawhistory /><Headercommon /></>} />
            <Route path="/Add-fund-history" element={<><Addfoundhistory /><Headercommon /></>} />
            <Route path="/Wallet-history" element={<><Wallethistory /><Headercommon /></>} />



          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
