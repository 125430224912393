import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import loading from '../../assets/img/loading-gif.gif';
import filesearch from '../../assets/img/filesearch.png';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Container, Form, Row, Col, ListGroup } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
const url = new URL(window.location.href);
const gameid = url.searchParams.get('id');
const url1 = new URL(window.location.href);
const name = url1.searchParams.get('name');

export default function Jodi() {
  const blockInvalidChar = (e) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
  // const data = Array.from({ length: 100 }, (_, index) => `Item ${index + 1}`);
  const data = Array.from({ length: 100 }, (_, index) => `Item ${String(index + 0).padStart(2, '0')}`);
  const [inputValues, setInputValues] = useState(Array(data.length).fill(''));
  const [users, setUsers] = useState([]);
  const [areAnyInputsEmpty, setAreAnyInputsEmpty] = useState(false);
  const [session_name, setSessionname] = useState([]);
  const [setminibet, setMinibet] = useState([]);
  const [MaxbetAmt, setMaxbet] = useState([]);
  const [Maxpoints, setMaxpoints] = useState([]);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [loading1, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const navigate = useNavigate();
  const [loadingbutton, setLoadingbutton] = useState(false);
  const market_name = localStorage.getItem("market_name");
  const [profiles, setProfiles] = useState([]); 
  const handleInputChange = (e, index) => {
    const newInputValues = [...inputValues];

    newInputValues[index] = e.target.value;
    setInputValues(newInputValues);

    const newTotalPoints = newInputValues.reduce((acc, value) => acc + Number(value), 0);
    setTotalPoints(newTotalPoints);
    console.log(`${index}, ${e.target.value}`);
  };

  useEffect(() => {
    const emptyInputs = inputValues.every((value) => value.trim() === '');
    setAreAnyInputsEmpty(emptyInputs);
  }, [inputValues]);

  useEffect(() => {
    loaduser();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loaduser = async () => {
    const gameid = localStorage.getItem("market_id");
    setLoading(true);
    const user_id = localStorage.getItem('userid');
    const dev_id = localStorage.getItem('dev_id');

    try {
      let url = `${process.env.REACT_APP_API_URL}/num_tbl.php`;
      const formData = new FormData();
      formData.append('app_id', process.env.REACT_APP_API_ID);
      formData.append('user_id', user_id);
      formData.append('market_id', gameid);
      formData.append('dev_id', dev_id);

      var config = {
        method: 'POST',
        url: url,
        body: formData,
      };

      axios.post(url, formData, config)
        .then(function (response) {
          var setmininumbet = response.data.mini_bet;
          var setmax_betnumbet = response.data.max_bet;
          var setpoints = response.data.points;
          setMinibet(setmininumbet)
          setMaxbet(setmax_betnumbet)
          setMaxpoints(setpoints)



          const res = JSON.stringify(response.data);
          const objectRes = JSON.parse(res);
          setUsers(objectRes);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setLoading(false);
    }
  }

  const playgame = async () => {
    const gameid = localStorage.getItem("market_id");
    const user_id = localStorage.getItem('userid');
    const dev_id = localStorage.getItem('dev_id');

    const isInvalidBet = inputValues.some((value) => {
      const numericValue = parseFloat(value);
      const miniBet = parseFloat(users.mini_bet);
      const maxBet = parseFloat(users.max_bet);

      if (numericValue < miniBet) {
        toast.error(`Bet value ${numericValue} is below the minimum bet (${miniBet}). Please check your input.`);
        return true;
      }

      if (numericValue > maxBet) {
        toast.error(`Bet value ${numericValue} is above the maximum bet (${maxBet}). Please check your input.`);
        return true;
      }

      return false;
    });

    if (isInvalidBet) {
      return;
    }

    if (users.points < totalPoints) {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: 'You Dont Have Sufficient Balance',
        timer: 2000,
        icon: 'error'
      });
      return;
    }

    setIsButtonVisible(true);

    try {
      setLoadingbutton(true);

      const betList = data.map((item, index) => ({
        // betkey: index,
        betkey: String(index).padStart(2, '0'),
        betamount: inputValues[index] || 0,
        bettype: 2,
        session_name: "close",
      })).filter(item => item.betamount > 0);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/play_game.php`, {
        user_id: user_id,
        dev_id: dev_id,
        market_id: gameid,
        app_id: process.env.REACT_APP_API_ID,
        BetList: betList,
        dev_model: "web",
        market_type: 'mainMarket',
        devName: "sumsang"
      });

      console.warn(response.data.success);

      if (response.data.success === '1') {
        setIsButtonDisabled(true);

        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: response.data.message,
          icon: 'success',
          timer: 2500
        }).then((result) => {
          navigate('/');
        });
      } else {
        toast.error(response.data.message);
        return;
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    } finally {
      setLoadingbutton(false);
    }
  };

  useEffect(() => {
    Getprofile();
  }, []);

  const Getprofile = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    var config = {
      method: "POST",
      url: url,
      data: formData, // Use 'data' instead of 'body'
    };

    axios(config)
      .then(function (response) {
        console.log("API Response:", response);
        const res = response.data;
        if (res !== undefined) {
          // alert(res);
          setProfiles(res);
          console.warn(res);
        }
      })
      .catch(function (error) {
        console.error("Error fetching profile data: ", error);
      });
  };
  const handelclick = () => {
    // alert('hhh')
    navigate(-1);
  }
  return (
    <>
    <div className="header-top">
        <Container>
          <Row className="d-flex align-items-center justify-content-between">
            <Col xs={2} md={2} s={2}>
              <Link onClick={handelclick} class="arrowlink">
                <FaArrowLeft />
              </Link>
            </Col>
            <Col xs={8} md={8} s={8}>
              <div className="menuicon d-flex justify-content-center">
                <div className="d-flex">
                  <div class="headericonarrow">
                  </div>
                  {market_name}
                </div>

              </div>
            </Col>

            <Col xs={2} md={2} s={2}>
              <div className="d-flex justify-content-end align-items-center">
                <div className="d-flex align-items-center wallet">
                  <Icon.Wallet /> :
                  {profiles.credit}
                </div>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <section id="playgame" className="margin-bottom-88 mb-5 fixed headerchat">
        <Container fluid className="p-0">
          {/* <div className="d-flex justify-content-between px-3 ">
            <div className="points">
              <h5>Points Remaining</h5>
              <p>{Math.max(0, users.points - totalPoints)}</p>
            </div>
            <div className="points">
              <h5>Points Added</h5>
              <p>{totalPoints}</p>
            </div>
          </div> */}
          <div className='d-flex flex-wrap justify-content-between'>
            {data.map((item, index) => (
              <div className='cardview' key={index}>
                <div className={`number ${inputValues[index] ? 'green' : ''}`}>{String(index + 0).padStart(2, '0')}</div>
                <input
                  type='number'
                  onKeyDown={blockInvalidChar}
                  className='form-input'
                  autoFocus={index === 0}
                  value={inputValues[index]}
                  onChange={(e) => { handleInputChange(e, index); }}
                  disabled={isButtonDisabled}

                />
              </div>

            ))}

            <div className='position-relative'>
              <div className='totalpoints d-flex justify-content-between align-items-center'>
                


                {/* <button
                  className='btn-add'
                  onClick={playgame}

                  disabled={loadingbutton || areAnyInputsEmpty}
                >
                  <span >Play</span>
                  {loadingbutton && <Spinner animation="border" />}

                </button> */}
                <div class="totalpoints d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-between align-items-center">
                    <p class="text-black">₹</p>
                    <p class="ms-1 text-black">{totalPoints} /-</p>
                  </div>
                  {users.betpoint_change_time > 0 ?
                  <button class="btn-add"  onClick={playgame} disdisabled={loadingbutton || areAnyInputsEmpty}>
                    <span>Submit</span>
                    {loadingbutton && <Spinner animation="border" />}
                  </button>
                   :
                   <button className='btn-add'>
                     <span >Bat Has Bin Colsed</span>
                   </button>
                 }
                </div>
              </div>

            </div>

          </div>
        </Container>
        <ToastContainer />
      </section>
      <section className='jodi' id='jodi'>


      </section>

      {loading2 && (
        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}

    </>
  );
}
